import React from "react";
import { object, func, bool } from "prop-types";
import { Tooltip, IconButton } from "@material-ui/core";
import { Settings as SettingsIcon } from "@material-ui/icons";
import InboxMenuItem from "./Inbox/InboxMenuItem.jsx";
import { useIntl } from "react-intl";
import CompanyMenu from "./CompanyMenu/CompanyMenu";
import MainNavigation from "./MainNavigation/MainNavigation";
import UserMenu from "./UserMenu/UserMenu";
import { VOXJAR_API_SERVER_URI, SITE_CONFIG } from "../../../config.js";
import "./Header.scss";

// eslint-disable-next-line no-undef
const IS_PRODUCTION = process.env.NODE_ENV === "production";
const usingProdLocally =
  !IS_PRODUCTION && VOXJAR_API_SERVER_URI.indexOf("9000") > -1;

const propTypes = {
  user: object,
  location: object,
  onPathChange: func,
  onboarding: bool
};

const Header = ({ user, location, onPathChange, onboarding }) => {
  const intl = useIntl();
  const styles = {
    icon: {
      margin: "0 4px",
      color: "#fff"
    }
  };
  return (
    <div styleName="container">
      {!onboarding ? (
        <div styleName={`toolbar ${usingProdLocally ? "prod" : ""}`}>
          <div styleName="toolbar-section">
            <a href="/" styleName="link">
              <span styleName="toolbar-title">{SITE_CONFIG.navName}</span>
            </a>
            <MainNavigation userInfo={user} />
          </div>
          <div styleName="toolbar-section">
            {SITE_CONFIG.Inbox && (
              <InboxMenuItem onPathChange={onPathChange} user={user} />
            )}
            {SITE_CONFIG.Settings && (
              <Tooltip
                title={intl.messages["settings"] || "Settings"}
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  aria-label="Settings"
                  style={styles.icon}
                  onClick={() => onPathChange("/settings")}
                >
                  <SettingsIcon
                    color={
                      location.pathname.indexOf("settings") > -1
                        ? "secondary"
                        : "inherit"
                    }
                  />
                </IconButton>
              </Tooltip>
            )}
            <CompanyMenu user={user} />
            <UserMenu onPathChange={onPathChange} />
          </div>
        </div>
      ) : (
        <div styleName="toolbar onboarding">
          <div styleName="toolbar-section">
            <a href="/" styleName="link">
              <span styleName="toolbar-title">{SITE_CONFIG.navName}</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
